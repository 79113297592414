import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Features2Grid from "../components/features-2-grid"
import Features4Grid from "../components/features-4-grid"
import CallToACtion from "../components/cta"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "aerztinnen-und-team"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              intro {
                text
                title
              }
              gridCols2 {
                image
                subtitle
                title
                text
              }
              gridCols4 {
                image
                subtitle
                title
                text
              }
              cta {
                subtitle
                title
                text
                button {
                  text
                  link
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function About(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Head title={data.title} description={data.intro.text} robots="index, follow" />
      <Intro subtitle={data.title} title={data.intro.title} titleHeading="h1" text={data.intro.text} />
      <Features2Grid gridCols2={data.gridCols2} />
      <Features4Grid gridCols4={data.gridCols4} gridColsMobile="2" colClass="" />
      <CallToACtion subtitle={data.cta.subtitle} title={data.cta.title} text={data.cta.text} button={data.cta.button} />
    </Layout>
  )
}
