import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function CallToAction(props) {
  return (
    <div className="bg-green-light py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-center">
        {props.subtitle && <h4 className="mb-4 text-base font-semibold text-white">{props.subtitle}</h4>}
        {props.title && <h3 className="max-w-xl mx-auto text-4xl !leading-[1.1] font-serif sm:text-5xl lg:text-6xl text-white">{props.title}</h3>}
        {props.text && <p className="mt-6 max-w-3xl mx-auto leading-normal text-white text-center text-base md:text-lg">{props.text}</p>}
        {props.button && <AniLink paintDrip to={props.button.link} duration={1} hex="F5DCAD" className="mt-8 inline-flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-transparent text-base  font-semibold rounded-full text-petrol bg-white hover:bg-green hover:text-white whitespace-nowrap transition-colors"
        >{props.button.text}</AniLink>}
      </div>
    </div>
  )
}
