import React from "react"
import Image from "../components/image"

export default function Features2Grid(props) {
  return (
    <div className="my-16 sm:my-24 lg:my-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          {props.gridCols2.map((item, i) => {
            return (
              <div key={i}>
                {item.image && <Image
                  filename={item.image.split('/assets/').pop()}
                  alt={item.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                  className="mb-6"
                />}
                {item.subtitle && <h4 className="text-base font-semibold text-petrol">{item.subtitle}</h4>}
                {item.title && <h3 className="mt-2 text-2xl font-serif text-petrol sm:text-3xl lg:text-4xl">
                  {item.title}
                </h3>}
                {item.text && <p className="mt-3 text-base md:text-lg text-petrol">{item.text}</p>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
